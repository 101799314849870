var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _vm.stepStatus == "" ? _c("account-information") : _vm._e(),
      _vm.stepStatus == "inValidation" ? _c("email-validation") : _vm._e(),
      _vm.stepStatus == "validated" ? _c("waiting-for-validation") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }