var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex justify-content-end" }, [
    _vm.isLoaded
      ? _c(
          "div",
          {
            staticClass:
              "h-100 d-flex justify-content-end align-items-center position-relative",
          },
          [
            _vm._m(0),
            _c(
              "a",
              {
                staticClass: "account-box me-3",
                attrs: { href: _vm.espaceElearningLink },
              },
              [
                _c("span", { staticClass: "d-none d-md-block" }, [
                  _vm._v(" Espace e-learning"),
                ]),
                _c("span", { staticClass: "d-md-none" }, [
                  _vm._v("E-learning"),
                ]),
                _c("svg-icon", {
                  attrs: {
                    "icon-class": "e-learning-white",
                    className: "account-box-icon ms-1",
                  },
                }),
              ],
              1
            ),
            _vm.isAuthenticated && _vm.user?.id
              ? _c(
                  "a",
                  {
                    staticClass: "account-box me-3",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.showLoginCard.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", {}, [_vm._v(" Mon compte")]),
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": "account",
                        className: "account-box-icon ms-1 ms-1",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "a",
                  {
                    staticClass: "account-box",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.showLoginCard.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", {}, [_vm._v("Mon compte")]),
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": "account",
                        className: "account-box-icon ms-1",
                      },
                    }),
                  ],
                  1
                ),
            _vm.isAuthenticated && _vm.user?.id
              ? _c(
                  "a",
                  {
                    staticClass: "cart account-box",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.goToCart.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "d-none d-md-block" }, [
                      _vm._v("Mon panier"),
                    ]),
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": "cart-icon",
                        className: "cart-icon",
                      },
                    }),
                    _c("span", { staticClass: "cart-items-number" }, [
                      _c("span", { staticClass: "number" }, [
                        _vm._v(" " + _vm._s(_vm.cartTotalItems) + " "),
                      ]),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _c(
          "div",
          {
            staticClass: "spinner-border spinner-border-sm text-light me-2",
            attrs: { role: "status" },
          },
          [_c("span", { staticClass: "sr-only" })]
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "account-box me-2",
        attrs: {
          id: "catalog-formation-download-desktop",
          href: "/catalogues-formation/",
          target: "",
        },
      },
      [
        _c("span", { staticClass: "d-none d-md-block" }, [
          _vm._v("Catalogue Formation"),
        ]),
        _c("span", { staticClass: "d-md-none" }, [_vm._v("Catalogue")]),
        _c("img", {
          staticClass: "mx-2 mb-1",
          attrs: {
            src: "/wp-content/uploads/2024/02/icon-book.png",
            width: "20",
            height: "20",
            alt: "Catalogue Formation",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }