<template>
  <div>
    <div class="badge-payment"></div>
  </div>
</template>

<script>
export default {
  name: 'BadgePayment3x4x',
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.badge-payment {
  width: 60px;
  aspect-ratio: 1 / 1;
  background-image: url(../assets/pastille-paiement-3x-4x.svg);
  background-size: cover;

  @media (min-width: 400px) {
    width: 70px;
  }
  @media (min-width: 576px) {
    width: 80px;
  }
  @media (min-width: 768px) {
    width: 70px;
  }
  @media (min-width: 992px) {
    width: 90px;
  }
  @media (min-width: 1200px) {
    position: relative;
    width: 130px;
    bottom: 50px;
 
  }
}
</style>
