var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "mb-3" }, [
      _c("label", { staticClass: "col-form-label", class: _vm.labelClass }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _c("textarea", {
        class: _vm.inputClass,
        attrs: { rows: _vm.rows, placeholder: _vm.placeholder },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.handleInput($event.target.value)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }