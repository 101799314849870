export const SET_API_BASEURL = 'SET_API_BASEURL'
export const SET_USER = 'SET_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const SET_SUPPORT = 'SET_SUPPORT'
export const SHOW_SUPPORT = 'SHOW_SUPPORT'
export const HIDE_SUPPORT = 'HIDE_SUPPORT'
export const SET_METIERS = 'SET_METIERS'
export const SET_ESTABLISHMENTS = 'SET_ESTABLISHMENTS'
export const SET_DIPLOMAS = 'SET_DIPLOMAS'
export const SET_LOGIN = 'SET_LOGIN'
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const SET_CART_ITEMS = 'SET_CART_ITEMS'
export const SET_CART_TOTAL_ITEMS = 'SET_CART_TOTAL_ITEMS'
export const UPDATE_VAT = 'UPDATE_VAT'
export const UPDATE_VAT_CODE = 'UPDATE_VAT_CODE'
export const UPDATE_VAT_NAME = 'UPDATE_VAT_NAME'
export const UPDATE_PAYMENT_TYPE = 'UPDATE_PAYMENT_TYPE'
export const CLEAR_CART = 'CLEAR_CART'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_PARCOURS = 'SET_PARCOURS'
export const SET_REGIONS = 'SET_REGIONS'
export const SET_CITIES = 'SET_CITIES'
export const SET_TRAININGS = 'SET_TRAININGS'
export const SET_DEVIS_TYPES = 'SET_DEVIS_TYPES'
export const SET_PAYMENT_PLANS = 'SET_PAYMENT_PLANS'
export const SET_IS_1X_ELIGIBLE = 'SET_IS_1X_ELIGIBLE'
export const SET_IS_3X_ELIGIBLE = 'SET_IS_3X_ELIGIBLE'
export const SET_IS_4X_ELIGIBLE = 'SET_IS_4X_ELIGIBLE'
export const SHOW_NURSE = 'SHOW_NURSE'
export const HIDE_NURSE = 'HIDE_NURSE'
export const SET_NURSE_MESSAGE = 'SET_NURSE_MESSAGE'
export const SET_MERCHANT_ID = 'SET_MERCHANT_ID'
export const SET_VIDEO_MODAL = 'SET_VIDEO_MODAL'
export const SET_CODE_PROMO_DATA = 'SET_CODE_PROMO_DATA'
