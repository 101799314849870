import request from '@/utils/request'
import Cookies from 'js-cookie'
export function fetchMetiers() {
  return request({
    url: '/metiers',
    method: 'get',
  })
}
export function fetchEstablishments() {
  const cachedEstablishments = Cookies.get('establishments')
  if (!cachedEstablishments) {
    return request({
      url: '/establishments',
      method: 'get',
    }).then((data) => {
      let inOneHour = 1 / 24
      Cookies.set('establishments', JSON.stringify(data.data), {
        expires: inOneHour,
      })
      return data
    })
  } else {
    let data = {
      data: JSON.parse(cachedEstablishments),
    }
    return data
  }
}
export function fetchDiplomas() {
  const cachedDiploma = Cookies.get('diplomas')
  if (!cachedDiploma) {
    return request({
      url: '/diplomas',
      method: 'get',
    }).then((data) => {
      let inOneHour = 1 / 24
      Cookies.set('diplomas', JSON.stringify(data.data), {
        expires: inOneHour,
      })
      return data
    })
  } else {
    let data = {
      data: JSON.parse(cachedDiploma),
    }
    return data
  }
}
export function storeParticipation(data) {
  return request({
    url: '/participations',
    method: 'post',
    data: data,
  })
}
export function updateParticipation(data) {
  return request({
    url: '/participations/' + data.id,
    method: 'put',
    data: data.payload,
  })
}

export function setUserInformations(data) {
  console.log('data = ', data)
  return request({
    url: '/customers',
    method: 'post',
    data: data,
  })
}
export function updateUserData(data) {
  return request({
    url: '/customers/' + data.id,
    method: 'put',
    data: data.data,
  })
}
export function confirmUserAccount(data) {
  return request({
    url: '/account-confirmation',
    method: 'post',
    data: data,
  })
}
export function resendEmail(data) {
  console.log('data = ', data)
  return request({
    url: '/forwarding-email',
    method: 'post',
    data: data,
  })
}
