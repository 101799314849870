var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-4" },
    [
      _c("div", [
        _c("h2", { staticClass: "h2-dark mb-5" }, [
          _vm._v("Félicitations, votre espace personnel a bien été créé !"),
        ]),
        _c("h4", { staticClass: "h4 text-primary mb-4" }, [
          _vm._v(
            " Merci pour ces précieuses information votre inscription est désormais effective. Vous pouvez consulter le catalogue et vous inscrire aux sessions de formations souhaitées. "
          ),
        ]),
        _c("div", { staticClass: "row mt-4 mb-5" }, [
          _c(
            "div",
            {
              staticClass:
                "align-items-center col-lg-12 col-sm-12 d-flex flex-wrap gap-2 justify-content-end justify-content-lg-start mb-5",
            },
            [
              _c("svg-icon", {
                attrs: { "icon-class": "hand", "class-name": "hand-icon" },
              }),
              _c("atom-button", {
                attrs: {
                  "btn-class": "btn btn-primary ms-3 pe-4 ps-3",
                  type: "button",
                  title: "Espace personnel",
                },
                on: { click: _vm.goToMyAccount },
              }),
              _c("atom-button", {
                attrs: {
                  "btn-class": "btn btn-primary ms-3 pe-2 ps-2",
                  type: "button",
                  title: "Trouver votre formation",
                },
                on: { click: _vm.goHome },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("support-box"),
      _c("error-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }