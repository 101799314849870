<template>
  <div class="p-4">
    <div class="row mb-5">
      <div class="col-md-12 mb-5">
        <h3 class="h3 mb-4">Veuillez vérifier votre email</h3>
        <h4 class="h4 text-primary mb-5 fw-bold"
          >Nous avons enregistré vos informations de compte. Maintenant, nous
          allons vérifier votre adresse e-mail.<br />
          Pour valider votre compte, cliquez sur le lien dans l'e-mail que nous
          avons envoyé à
          {{ user.email }}</h4
        >
        <p
          >Vous n'avez pas reçu?
          <a
            href="#"
            class="text-decoration-underline"
            @click.stop.prevent="resendEmailtoConfirm"
          >
            Ré-envoyer l'email</a
          ></p
        >
      </div>
    </div>
    <support-box />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import SupportBox from '@/components/SupportBox.vue'
const Swal = require('sweetalert2')
export default {
  name: 'EmailValidation',
  components: {
    SupportBox,
  },
  data() {
    return {
      Toast: Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
      }),
    }
  },
  computed: {
    ...mapFields('app', ['user']),
    ...mapFields('inscription', ['steps']),
  },
  created() {
    const support = {
      header: 'Consultez vos emails !',
      content: ``,
    }
    this.$store.commit('app/SET_SUPPORT', support)
    this.$store.commit('app/SHOW_SUPPORT')
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    resendEmailtoConfirm() {
      let data = {}
      let email = this.$lodash.get(this.user, 'email', '')
      data.email = email
      this.$store
        .dispatch('inscription/resendEmailConfirmation', data)
        .then((response) => {
          if (response.data.status == 200) {
            this.Toast.fire({
              icon: 'success',
              title: 'L’email a été renvoyé avec succès',
            })
          }
        })
    },
  },
}
</script>

<style></style>
