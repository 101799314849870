var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._m(1),
      _c("div", { staticClass: "p-4" }, [
        _c("div", { staticClass: "row mb-5" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("atom-button", {
                attrs: {
                  "btn-class": "btn btn-primary m-auto mt-5",
                  type: "button",
                  title: "Compléter les informations",
                },
                on: { click: _vm.next },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("support-box"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "p-4" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("h2", { staticClass: "h2 h2-dark mb-3" }, [
            _vm._v("Veuillez finaliser l’inscription"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "bloc-primary-light d-flex justify-content-center p-5" },
      [
        _c("h4", { staticClass: "h4 text-primary fw-bold" }, [
          _vm._v(" Merci pour la validation de votre email."),
          _c("br"),
          _vm._v(
            "Vous devez terminer de remplir le formulaire pour finaliser votre dossier et pouvoir accéder à votre espace personnel pour ouvrir des droits à l’inscription aux sessions"
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }