import { getField, updateField } from 'vuex-map-fields'
import defaultSettings from '@/settings'
const { title } = defaultSettings
const state = { title }

const mutations = {
  updateField,
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
}

const actions = {}

const getters = { getField }

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
