<template>
  <div class="">
    <account-information />
    <!-- <email-validation v-if="stepStatus == 'inValidation'" /> -->
    <!-- <waiting-for-validation v-if="stepStatus == 'validated'" /> -->
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import AccountInformation from './AccountInformation.vue'
import EmailValidation from './EmailValidation.vue'
import WaitingForValidation from './WaitingForValidation.vue'
export default {
  name: 'One',
  components: {
    AccountInformation,
    EmailValidation,
    WaitingForValidation,
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields('inscription', [
      'steps',
      'currentStep',
      'isAccountInformationCompleted',
    ]),
    stepStatus() {
      return this.steps[0].status
    },
  },
  mounted() {
    this.$nextTick(() => {})
  },
  methods: {},
}
</script>

<style></style>
