import { getField, updateField } from 'vuex-map-fields'
const state = {
  EditAccountInformationsModal: '',
  changePasswordModal: '',
}

const mutations = {
  updateField,
}

const actions = {}

const getters = { getField }

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
