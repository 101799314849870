var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "inscription-error-modal",
        "data-bs-backdrop": "static",
        "data-bs-keyboard": "false",
        tabindex: "-1",
        "aria-labelledby": "cartModalLabel",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog errorModal modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content border-0" }, [
            _vm._m(0),
            _c("div", { staticClass: "modal-body pt-4" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6 col-sm-12 mb-3" }, [
                  _c("h1", { staticClass: "h1-primary mb-5" }, [
                    _vm._v("Inscription"),
                  ]),
                  _c("h4", { staticClass: "h4-dark fw-bold mb-4" }, [
                    _vm._v(" " + _vm._s(_vm.message) + " "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row d-block" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-6 col-lg-5 mb-3" },
                  [
                    _c("atom-button", {
                      attrs: {
                        "btn-class": "btn btn-primary  w-100",
                        type: "button",
                        title: "Nouveau compte",
                      },
                      on: { click: _vm.goToCart },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-sm-6 col-lg-5 mb-2" },
                  [
                    _c("atom-button", {
                      attrs: {
                        "btn-class": "btn btn-dark  w-100",
                        type: "button",
                        title: "Se connecter",
                      },
                      on: { click: _vm.login },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header border-0" }, [
      _c("h5", { staticClass: "modal-title", attrs: { id: "cartModalLabel" } }),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }