var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "section__inscription-steps p-4" }, [
    _c(
      "ul",
      _vm._l(_vm.steps, function (step, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "step",
            class: {
              "step-active": _vm.currentStep.number == step.number,
              "step-icon": step.status != "",
            },
          },
          [_c("step", { attrs: { step: step } })],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }