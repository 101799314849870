import { render, staticRenderFns } from "./BadgePayment3x4x.vue?vue&type=template&id=265791bc&scoped=true&"
import script from "./BadgePayment3x4x.vue?vue&type=script&lang=js&"
export * from "./BadgePayment3x4x.vue?vue&type=script&lang=js&"
import style0 from "./BadgePayment3x4x.vue?vue&type=style&index=0&id=265791bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "265791bc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/varjak-edbn/edbn-theme/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('265791bc')) {
      api.createRecord('265791bc', component.options)
    } else {
      api.reload('265791bc', component.options)
    }
    module.hot.accept("./BadgePayment3x4x.vue?vue&type=template&id=265791bc&scoped=true&", function () {
      api.rerender('265791bc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BadgePayment3x4x.vue"
export default component.exports