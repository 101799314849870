var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn",
      class: `d-flex justify-content-center ${_vm.btnClass}`,
      attrs: { type: _vm.type, disabled: _vm.disabled },
      on: {
        click: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.handleClick.apply(null, arguments)
        },
      },
    },
    [
      _vm.spinner
        ? _c(
            "div",
            {
              staticClass: "spinner-border spinner-border-sm text-light me-2",
              attrs: { role: "status" },
            },
            [_c("span", { staticClass: "sr-only" })]
          )
        : _vm._e(),
      _vm.icon
        ? _c("svg-icon", {
            attrs: { "icon-class": _vm.icon, className: _vm.iconClassName },
          })
        : _vm._e(),
      _c("div", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }