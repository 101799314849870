var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("div", [
      _vm.step.status == ""
        ? _c("div", { staticClass: "step__number" }, [
            _vm._v(" " + _vm._s(_vm.step.number) + " "),
          ])
        : _vm._e(),
      _vm.step.status == "inValidation" || _vm.step.status == "validated"
        ? _c(
            "div",
            {},
            [
              _c("svg-icon", {
                attrs: { "icon-class": "cog", className: "icon" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.step.status == "completed"
        ? _c(
            "div",
            {},
            [
              _c("svg-icon", {
                attrs: { "icon-class": "check", className: "icon" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.lgBreakpoint
        ? _c("div", { staticClass: "step__title" }, [
            _vm._v(_vm._s(_vm.step.title)),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }