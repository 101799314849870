<template>
  <div class="d-flex justify-content-end">
    <div
      v-cloak
      v-if="isLoaded"
      class="h-100 d-flex justify-content-end align-items-center position-relative"
    >
      <a
        id="catalog-formation-download-desktop"
        class="account-box me-2"
        href="/catalogues-formation/"
        target=""
        ><span class="d-none d-md-block">Catalogue Formation</span>
        <span class="d-md-none">Catalogue</span>
        <img
          src="/wp-content/uploads/2024/02/icon-book.png"
          class="mx-2 mb-1"
          width="20"
          height="20"
          alt="Catalogue Formation"
      /></a>
      <a :href="espaceElearningLink" class="account-box me-3">
        <span class="d-none d-md-block"> Espace e-learning</span>
        <span class="d-md-none">E-learning</span>
        <svg-icon
          icon-class="e-learning-white"
          className="account-box-icon ms-1"
        />
      </a>
      <a
        v-if="isAuthenticated && user?.id"
        class="account-box me-3"
        @click.stop.prevent="showLoginCard"
      >
        <span class=""> Mon compte</span>
        <svg-icon icon-class="account" className="account-box-icon ms-1 ms-1" />
      </a>
      <a v-else class="account-box" @click.stop.prevent="showLoginCard">
        <span class="">Mon compte</span>
        <svg-icon icon-class="account" className="account-box-icon ms-1" />
      </a>
      <a
        class="cart account-box"
        v-if="isAuthenticated && user?.id"
        @click.stop.prevent="goToCart"
      >
        <span class="d-none d-md-block">Mon panier</span>
        <svg-icon icon-class="cart-icon" className="cart-icon" />
        <span class="cart-items-number">
          <span class="number">
            {{ cartTotalItems }}
          </span>
        </span>
      </a>
    </div>
    <div
      v-else
      class="spinner-border spinner-border-sm text-light me-2"
      role="status"
    >
      <span class="sr-only"></span>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'Navbar',
  components: {},
  data() {
    return {
      sideNavigationOpen: false,
      isLoaded: false,
    }
  },
  computed: {
    ...mapFields('app', ['isCardLoginIsShowing', 'user', 'isAuthenticated']),
    ...mapFields('cart', ['cartData', 'cartTotalItems']),
    espaceElearningLink() {
      return '/e-learning-page'
    },
  },
  mounted() {
    this.$bus.$on('authentication.changed', () => {
      this.isLoaded = true
    })
  },
  destroyed() {},
  methods: {
    showLoginCard() {
      this.isCardLoginIsShowing = !this.isCardLoginIsShowing
      if (this.isCardLoginIsShowing) {
        window.dataLayer.push({
          event: 'se_connecter',
          category: 'login',
          action: 'display',
          label: 'connect',
        })
      }
    },
    goToCart() {
      window.location = '/panier'
    },
  },
}
</script>

<style lang="scss" scoped>
.cart {
  position: relative;
  cursor: pointer;

  &-icon {
    cursor: pointer;
    width: 1.498rem !important;
    height: 1.25rem !important;
    margin-left: 1rem;
  }
}

.cart-items-number {
  position: absolute;
  top: 0;
  left: 80%;
  width: 14px;
  height: 14px;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: red;
  color: white;
  font-weight: 700;
  border-radius: 50%;
  font-size: 0.813rem;

  .number {
    font-size: 0.6rem;
  }
}
</style>
