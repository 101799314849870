<template>
  <div class="section__inscription-steps p-4">
    <ul>
      <li
        class="step"
        :class="{
          'step-active': currentStep.number == step.number,
          'step-icon': step.status != '',
        }"
        v-for="(step, index) in steps"
        :key="index"
      >
        <step :step="step" />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Step from './Step.vue'
export default {
  name: 'ProgressInscription',
  components: {
    Step,
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields('inscription', ['steps', 'currentStep']),
  },
  mounted() {},
  methods: {},
}
</script>

<style></style>
