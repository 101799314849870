var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "label",
      {
        staticClass: "col-form-label",
        class: _vm.labelClass,
        attrs: { for: "" },
      },
      [_vm._v(_vm._s(_vm.label))]
    ),
    _c(
      "select",
      {
        ref: "my-select",
        class: _vm.selectClass,
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.handleInput($event.target.value)
          },
        },
      },
      [
        _c("option", { attrs: { value: "", selected: "" } }, [
          _vm._v(_vm._s(_vm.placeholder)),
        ]),
        _vm._l(_vm.options, function (option, index) {
          return _c(
            "option",
            { key: index, domProps: { value: option.id || option.slug } },
            [_vm._v(" " + _vm._s(option.name) + " ")]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }