<template>
  <div>
    <div class="mb-3">
      <label class="col-form-label" :class="labelClass">{{ label }}</label>
      <textarea
        :class="inputClass"
        :rows="rows"
        :value="value"
        :placeholder="placeholder"
        @input="handleInput($event.target.value)"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AtomTextArea',
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    inputClass: {
      required: false,
      default: '',
    },
    labelClass: {
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: true,
    },
    rows: {
      type: String,
      required: false,
      default: '5',
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>
