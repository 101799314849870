<template>
  <div class="p-4">
    <h3 class="h3-dark mb-4">Compte en attente de validation </h3>
    <h4 class="h4 text-primary mb-4 fw-bold">Définir votre profil : </h4>
    <ValidationObserver ref="observer">
      <form autocomplete="off" method="">
        <div class="col-md-12">
          <div class="mb-3 row">
            <validation-provider
              name="phone"
              rules="required"
              v-slot="{ errors }"
              class=""
              mode="eager"
            >
              <div class="col-lg-3 col-sm-12">
                <label class="label-dark label-lg" for=""
                  >Numéro de téléphone</label
                >
                <VuePhoneNumberInput
                  @update="onCompanyPhoneUpdate"
                  v-model="phone"
                  placeholder="Tél"
                  default-country-code="FR"
                  :clearable="true"
                  :border-radius="15"
                  :preferred-countries="['FR', 'BE']"
                  :translations="{
                    countrySelectorLabel: 'Code pays',
                    countrySelectorError: 'Choisir un pays',
                    phoneNumberLabel: 'Numéro de téléphone',
                    example: 'Exemple :',
                  }"
                  :class="{
                    'form-control-light': true,
                    'is-invalid': errors.length,
                  }"
                />
              </div>
            </validation-provider>
          </div>

          <div class="row mb-3">
            <validation-provider
              name="address"
              rules="required"
              v-slot="{ errors }"
              class=""
              mode="eager"
            >
              <div class="col-lg-6 col-sm-12">
                <atom-input
                  v-model="address"
                  placeholder="Adresse"
                  label="Votre adresse postale"
                  type="text"
                  :input-class="{
                    'form-control': true,
                    'is-invalid': errors.length,
                  }"
                  label-class="label-dark label-lg"
                />
              </div>
            </validation-provider>
          </div>
          <div class="row">
            <validation-provider
              name="country"
              rules="required"
              v-slot="{ errors }"
              class=""
              mode="eager"
            >
              <div class="col-lg-4 col-sm-12">
                <treeselect
                  v-if="lgBreakpoint"
                  v-model="country"
                  :multiple="false"
                  :options="countries"
                  :class="{
                    'is-invalid': errors.length,
                  }"
                  no-options-text="Aucune option disponible."
                  no-results-text="Aucun résultat trouvé ..."
                  placeholder="Pays"
                  :normalizer="normalizer"
                />
                <select
                  v-else
                  v-model="country"
                  :class="{
                    'form-select': true,
                    'is-invalid': errors.length,
                  }"
                >
                  <option :value="null">Pays</option>
                  <option
                    v-for="item in countries"
                    :value="item.name"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </validation-provider>
          </div>
          <div class="row mb-3">
            <validation-provider
              name="zipcode"
              rules="required"
              v-slot="{ errors }"
              class="col-lg-2 col-sm-12"
              mode="eager"
            >
              <atom-input
                v-model="zipcode"
                placeholder="CP"
                type="text"
                :input-class="{
                  'form-control': true,
                  'is-invalid': errors.length,
                }"
                label-class="label-dark label-lg"
              />
            </validation-provider>
            <validation-provider
              name="city"
              rules="required"
              v-slot="{ errors }"
              class="col-lg-2 col-sm-12"
              mode="eager"
            >
              <label for=""></label>
              <atom-input
                v-model="city"
                placeholder="Ville"
                type="text"
                :input-class="{
                  'form-control': true,
                  'is-invalid': errors.length,
                }"
                label-class="d-none"
              />
            </validation-provider>
          </div>
          <div class="row mb-3">
            <div class="col-lg-4 col-sm-12">
              <label class="label label-lg" for="">Votre Diplôme</label>
              <div
                class="my-2"
                v-for="(diploma, index) in userDiplomas"
                :key="'DP-' + index"
              >
                <validation-provider
                  name="diplomas"
                  :rules="{ required: userDiplomas[index] != 0 }"
                  v-slot="{ errors }"
                  class=""
                >
                  <treeselect
                    v-if="lgBreakpoint"
                    v-model="userDiplomas[index]"
                    :multiple="false"
                    :options="diplomasOptions"
                    :class="{
                      'is-invalid': errors.length,
                     ' mb-4': true
                    }"
                    defaultOptions=""
                    no-options-text="Aucune option disponible."
                    no-results-text="Aucun résultat trouvé ..."
                    placeholder="Diplôme"
                    :normalizer="normalizerDiploma"
                  />
                  <select
                    v-else
                    v-model="userDiplomas[index]"
                    :class="{
                      'form-select': true,
                      'is-invalid': errors.length,
                    }"
                  >
                    <option :value="0">Diplôme</option>
                    <option
                      v-for="diploma in diplomasOptions"
                      :value="diploma.id"
                      :key="diploma.id"
                    >
                      {{ diploma.name }}
                    </option>
                  </select>
                  <span v-if="errors.length"></span>
                </validation-provider>
                <div class="align-items-center d-flex">
                  <button
                    class="btn mt-3"
                    @click.stop.prevent="deleteDiploma(index)"
                    v-if="userDiplomas.length > 1"
                  >
                    <svg-icon icon-class="trash" className="trash" />
                  </button>
                </div>
              </div>
              <atom-button
                @click="AjouterDiplome"
                btn-class="btn btn-primary-light mb-3"
                type="button"
                title="+ Ajouter d'autres diplômes"
              />
            </div>
          </div>
          <!-- <div class="row mb-3">
            <div class="col-lg-3 col-sm-12">
              
            </div>
          </div> -->
          <div class="row mb-3">
            <validation-provider
              name="job"
              rules="required"
              v-slot="{ errors }"
              class="col-lg-4 col-sm-12"
            >
              <label class="label-dark label-lg mt-2" for=""
                >Votre Métier</label
              >
              <treeselect
                v-if="lgBreakpoint"
                v-model="job"
                :multiple="false"
                :options="jobOptions"
                :class="{
                  'is-invalid': errors.length,
                  'my-2': true,
                }"
                no-options-text="Aucune option disponible."
                no-results-text="Aucun résultat trouvé ..."
                placeholder="Métier"
              />
              <select
                v-else
                v-model="job"
                :class="{
                  'form-select': true,
                  'is-invalid': errors.length,
                }"
                class="my-2"
              >
                <option :value="null">Métier</option>
                <option
                  v-for="item in jobOptions"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.label }}
                </option>
              </select>
              <span v-if="errors.length"></span>
            </validation-provider>
          </div>
          <div class="row mb-3">
            <validation-provider
              name="clientType"
              rules="required"
              v-slot="{ errors }"
              class="col-lg-4 col-12"
            >
              <label class="label-dark label-lg mt-2" for=""
                >Type de client</label
              >
              <treeselect
                v-if="lgBreakpoint"
                v-model="clientType"
                :multiple="false"
                :options="clientTypes"
                :class="{
                  'is-invalid': errors.length,
                  'my-2': true,
                }"
                no-options-text="Aucune option disponible."
                no-results-text="Aucun résultat trouvé ..."
                placeholder="Type de client"
                :normalizer="normalizerDiploma"
              />
              <select
                v-else
                v-model="clientType"
                :class="{
                  'form-select': true,
                  'is-invalid': errors.length,
                }"
                class="my-2"
              >
                <option :value="null">Type de client</option>
                <option
                  v-for="item in clientTypes"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </validation-provider>
          </div>
          <div class="row">
            <validation-provider
              name="certify"
              :rules="{ required: { allowFalse: false } }"
              v-slot="{ errors }"
              class="col-lg-6 col-sm-12 mb-5 mb-3"
            >
              <atom-checkbox
                label="J'atteste sur l'honneur l’exactitude des informations et je m'engage à fournir tout justificatif de diplôme qui me serait demandé pour suivre une formation "
                link=""
                :label-class="{
                  'form-check-label label-dark fw-bold label-sm': true,
                  'is-invalid': errors.length,
                }"
                v-model="certify"
              />
            </validation-provider>
          </div>
          <div class="row">
            <div class="col-lg-3 col-sm-12 mb-5">
              <atom-button
                @click="next"
                btn-class="btn btn-primary d-flex mb-5 "
                type="button"
                title="Valider vos données"
                :disabled="isNextButtonDisabled"
                :spinner="isNextButtonDisabled"
              />
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <support-box />
    <error-modal />
  </div>
</template>

<script>
import { useBreakpoints, breakpointsBootstrapV5 } from '@vueuse/core'
import { mapFields } from 'vuex-map-fields'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Treeselect from '@riophae/vue-treeselect'
import AtomInput from '@/components/atoms/AtomInput'
import AtomButton from '@/components/atoms/AtomButton'
import SupportBox from '@/components/SupportBox.vue'
import AtomCheckbox from '@/components/atoms/AtomCheckbox'
import ErrorModal from '@/apps/signup/components/modals/ErrorModal.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import * as i18nIsoCountries from 'i18n-iso-countries'
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'))
const Swal = require('sweetalert2')
export default {
  name: 'Two',
  components: {
    Treeselect,
    AtomInput,
    AtomButton,
    SupportBox,
    AtomCheckbox,
    ErrorModal,
    VuePhoneNumberInput,
  },
  setup() {
    const breakpoints = useBreakpoints(breakpointsBootstrapV5)
    const lgBreakpoint = breakpoints.greater('sm')
    return { lgBreakpoint }
  },
  data() {
    return {
      normalizer(node) {
        return {
          id: node.name,
          label: node.name,
        }
      },
      normalizerDiploma(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
      isNextButtonDisabled: false,
      region: '',
      phone: '',
      address: '',
      zipcode: '',
      city: '',
      country: null,
      country2: null,
      job: null,
      accept: false,
      masked: true,
      selectedjobDiplomasIds: [],
      countryCode: 'FR',
      userDiplomas: [0],
      cities: [{ name: 'Paris' }],
      countries: [],
      clientType: null,
      clientTypes: [
        { id: 'ind', name: 'Particulier / Entreprise individuelle' },
        { id: 'etb', name: 'Structure / Grand Compte' },
      ],
      certify: false,
      Toast: Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
      }),
    }
  },
  computed: {
    ...mapFields('inscription', ['steps', 'currentStep']),
    ...mapFields('app', ['metiers', 'diplomas', 'user']),
    jobOptions() {
      let jobs = []
      this.$lodash.forEach(this.metiers, (job) => {
        jobs.push({
          id: job.id,
          label: job.name,
        })
      })
      return jobs
    },
    diplomasOptions() {
      return this.diplomas
    },
  },
  created() {
    const support = {
      header:
        'Afin d’étudier votre éligibilité à nos  formations, nous vous remercions de  bien vouloir remplir le questionnaire.',
      content: `Nous avons hâte de mieux vous connaitre !
`,
    }
    this.$store.commit('app/SET_SUPPORT', support)
    this.$store.commit('app/SHOW_SUPPORT')
    let countries = i18nIsoCountries.getNames('fr', { select: 'official' })
    this.countries = Object.entries(countries).map(([key, value]) => ({
      id: key,
      name: value,
    }))
    this.countries = this.$lodash.sortBy(this.countries, 'name')
    this.diplomas.push({
      id: 0,
      label: 'Diplôme',
    })
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    setSelectedDiploma(index) {
      this.userDiplomas[index] = this.selectedDiploma
    },
    onCompanyPhoneUpdate(data) {
      this.countryCode = data.countryCode
    },
    AjouterDiplome() {
      this.userDiplomas.push({
        id: null,
        label: null,
      })
    },
    deleteDiploma(index) {
      this.userDiplomas.splice(index, 1)
    },
    intersect(a, b) {
      return a.filter(Set.prototype.has, new Set(b))
    },
    next() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return
        }
        this.isNextButtonDisabled = true
        let jobs = []
        //find the object of the selected job
        jobs.push(this.metiers.find((job) => job.id == this.job))
        //extract the array of selected job diplomas
        let selectedjobDiplomas = jobs.map((job) => job.diplomas.data)
        //extract the array of selected job diplomas ids
        this.selectedjobDiplomasIds = selectedjobDiplomas[0].map(
          (job) => job.id
        )
        let isDiplomasExist = this.intersect(
          this.selectedjobDiplomasIds.map(Number),
          this.userDiplomas.map(Number)
        )
        if (isDiplomasExist.length < 1) {
          this.isNextButtonDisabled = false
          this.Toast.fire({
            icon: 'warning',
            title:
              "Vous n'avez pas indiqué de diplôme correspondant à votre métier",
          })
          return
        }
        console.log('this.user ', this.user)
        let data = {}
        data.phone = this.phone
        data.address = this.address
        data.zipcode = this.zipcode
        data.city = this.city
        data.country = this.country
        data.job_id = this.job
        data.diplomas = this.userDiplomas
        data.country_code = this.countryCode
        data.client_type = this.clientType
        data.step = 3
        let params = {
          data: data,
          id: this.user.id,
        }
        this.$store
          .dispatch('app/saveUserData', params)
          .then(() => {
            this.steps[1].status = 'completed'
            this.currentStep = this.steps[2]
          })
          .catch((error) => {
            this.isNextButtonDisabled = false
            let responseStatus = this.$lodash.get(
              error,
              'response.status',
              false
            )
            console.log(error.response.status)
            if (responseStatus == 421) {
              this.Toast.fire({
                icon: 'warning',
                title: 'Oups ! invalid code postal',
              })
            }
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.remove-circle {
  color: #db7657;
}

.trash {
  color: #ced4da;
  z-index: 3;
}

.vue-tel-input {
  border-radius: 15px;
  display: flex;
}

.vti__dropdown.open {
  background-color: transparent;
}
</style>
