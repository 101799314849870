var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "cart-modal",
        "data-bs-backdrop": "static",
        "data-bs-keyboard": "false",
        tabindex: "-1",
        "aria-labelledby": "cartModalLabel",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog cartModal modal-dialog-centered modal-sm",
        },
        [
          _c("div", { staticClass: "modal-content border-0" }, [
            _vm._m(0),
            _c("div", { staticClass: "modal-body p-5 pt-4" }, [
              _c("div", { staticClass: "col-lg-12 col-sm-12 mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-center text-primary fw-bold mb-3" },
                  [_vm._v("Session ajoutée au panier. Merci")]
                ),
                _c(
                  "div",
                  { staticClass: "col-lg-12 col-sm-12 mb-3" },
                  [
                    _c("atom-button", {
                      attrs: {
                        "btn-class": "btn btn-primary m-auto w-100",
                        type: "button",
                        title: "Aller au panier",
                      },
                      on: { click: _vm.goToCart },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-lg-12 col-sm-12 mb-2" },
                  [
                    _c("atom-button", {
                      attrs: {
                        "btn-class": "btn btn-primary m-auto w-100",
                        type: "button",
                        title: "Continuer ses achats",
                      },
                      on: { click: _vm.returnToArchive },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header border-0" }, [
      _c("h5", { staticClass: "modal-title", attrs: { id: "cartModalLabel" } }),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }