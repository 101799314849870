var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "support-box" }, [
    _vm.support && _vm.support.show
      ? _c("div", { staticClass: "support-box__body" }, [
          _c("div", { staticClass: "support-box__content" }, [
            _c("div", { staticClass: "support-box__head" }, [
              _vm._v(_vm._s(_vm.support.header)),
            ]),
            _c("div", { staticClass: "support-box__content" }, [
              _vm._v(_vm._s(_vm.support.content)),
            ]),
            _vm._m(0),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "support-box__footer" }, [
      _c(
        "button",
        { staticClass: "support-box__btn", on: { click: _vm.showHide } },
        [
          !_vm.support.show
            ? _c("svg-icon", {
                attrs: { "icon-class": "info-solid", className: "info-solid" },
              })
            : _c("svg-icon", {
                attrs: { "icon-class": "times", className: "times" },
              }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "support-box__pict" }, [
      _c("img", {
        attrs: { src: require("../assets/box-avatar.svg"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }