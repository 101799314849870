<template>
  <div class="p-4">
    <h2 class="h2-dark">Veuillez compléter les informations demandées</h2>
    <h4 class="h4 text-primary mb-2 fw-bold">Dans quelles structures avez-vous exercé :</h4>
    <ValidationObserver ref="observer">
      <form
        autocomplete="off"
        method=""
      >
        <div class="col-md-12">
          <div
            class="row"
            v-for="(experience, index) in experiences"
            :key="'EX-' + index"
          >
            <div class="mb-3 row">
              <validation-provider
                name="establishment"
                rules="required"
                v-slot="{ errors }"
                class="col-lg-4 col-sm-12 mt-3"
              >
                <label
                  class="col-form-label label-dark label-lg"
                  for=""
                >Etablissement:</label>
                <select
                  v-model="experiences[index].establishment"
                  :class="{
                    'form-select': true,
                    'is-invalid': errors.length,
                  }"
                >
                  <option :value="null">...</option>
                  <option
                    value=""
                    selected
                  >Etablissement</option>
                  <option
                    v-for="(option, index) in establishmentsOptions"
                    :value="option.id"
                    :key="index"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </validation-provider>
            </div>
            <div class="mb-3 row">
              <validation-provider
                name="year"
                rules="required"
                v-slot="{ errors }"
                class="col-lg-4 col-sm-12"
              >
                <label
                  class="col-form-label label-dark label-lg"
                  for=""
                >Année:</label>
                <select
                  v-model="experiences[index].year"
                  :class="{
                    'form-select': true,
                    'is-invalid': errors.length,
                  }"
                >
                  <option :value="null">...</option>
                  <option
                    value=""
                    selected
                  >Année</option>
                  <option
                    v-for="(option, index) in years"
                    :value="option.name"
                    :key="index"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </validation-provider>
            </div>
            <div class="mb-3 row">
              <validation-provider
                name="job"
                rules="required"
                v-slot="{ errors }"
                class="col-lg-4 col-sm-12"
              >
                <label
                  class="col-form-label label-dark label-lg"
                  for=""
                >Métier exercé:</label>
                <select
                  v-model="experiences[index].job"
                  :class="{
                    'form-select': true,
                    'is-invalid': errors.length,
                  }"
                >
                  <option :value="null">...</option>
                  <option
                    value=""
                    selected
                  >Métier</option>
                  <option
                    v-for="(option, index) in jobOptions"
                    :value="option.id"
                    :key="index"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </validation-provider>
            </div>
            <div class="row">
              <div class="col-lg-4 col-sm-12 align-items-center d-flex">
                <atom-button
                  @click="deleteExperience(index)"
                  btn-class="btn btn-gray mt-3 d-flex align-items-center"
                  type="button"
                  title="supprimer cette expericence"
                  icon="remove-circle"
                  icon-class-name="remove-circle me-1 "
                  v-if="experiences.length != 1"
                >
                </atom-button>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="row mt-4">
              <div class="col-lg-3 col-sm-12">
                <atom-button
                  @click="AddExperience"
                  btn-class="btn btn-primary-light m-auto "
                  type="button"
                  title="+ Ajouter une Expérience"
                />
              </div>
            </div>
            <div class="row mt-3">
              <label
                for=""
                class="label-dark label-lg mb-2"
              >Attester sur l’ honneur l’ exactitude des informations
                fournies. Certains documents pourront m’ être demandés par
                l’Ecole du Bien Naître.</label>
              <validation-provider
                name="certify"
                :rules="{ required: { allowFalse: false } }"
                v-slot="{ errors }"
                class="col-lg-12 col-sm-12"
              >
                <atom-checkbox
                  label="J'atteste sur l'honneur l’exactitude des informations et je m'engage à fournir tout justificatif de diplôme qui me serait demandé pour suivre une formation "
                  link=""
                  :label-class="{
                    'form-check-label label-dark fw-bold label-sm': true,
                    'is-invalid': errors.length,
                  }"
                  v-model="certify"
                />
              </validation-provider>
              <validation-provider
                v-if="isDoula"
                name="isDoula"
                :rules="{ required: { allowFalse: false } }"
                v-slot="{ errors }"
                class="col-lg-12 col-sm-12"
              >
                <atom-checkbox
                  label="J’atteste sur l’honneur avoir effectué 144 heures de formation en présentiel au sein des organismes référencés auprès de l’Association des Doulas de France et avoir plus de 6 mois d’expérience professionnelle. Je m’engage à fournir sur simple demande tout document justificatif à l’Ecole du Bien Naître."
                  link=""
                  :label-class="{
                    'form-check-label label-dark fw-bold label-sm': true,
                    'is-invalid': errors.length,
                  }"
                  v-model="isDoulaJob"
                />
              </validation-provider>
            </div>
            <div class="row mt-5 mb-5">
              <div class="col-lg-3 col-sm-12 mb-5">
                <atom-button
                  @click="next"
                  btn-class="btn btn-primary d-flex mb-5 "
                  type="button"
                  title="Valider vos données"
                  :disabled="isNextButtonDisabled"
                  :spinner="isNextButtonDisabled"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <support-box />
  </div>
</template>

<script>
const Swal = require('sweetalert2')
import { mapFields } from 'vuex-map-fields'
import AtomButton from '@/components/atoms/AtomButton'
import SupportBox from '@/components/SupportBox.vue'
import AtomCheckbox from '@/components/atoms/AtomCheckbox'
export default {
  name: 'Three',
  components: {
    AtomButton,
    SupportBox,
    AtomCheckbox,
  },
  data() {
    return {
      experiences: [
        {
          establishment: '',
          year: '',
          job: '',
        },
      ],
      radioOptions: [
        { key: 'Plus de 6 mois', value: '+6', id: '1+6' },
        { key: 'Moins de 6 mois', value: '-6', id: '1-6' },
      ],
      isNextButtonDisabled: false,
      certify: false,
      isDoulaJob: false,
      Toast: Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }),
    }
  },
  computed: {
    ...mapFields('inscription', ['steps', 'currentStep']),
    ...mapFields('app', ['metiers', 'diplomas', 'establishments', 'user']),
    jobOptions() {
      return this.metiers
    },
    isDoula() {
      return this.user.isDoulaJob
    },
    establishmentsOptions() {
      return this.establishments
    },
    years() {
      let max = new Date().getFullYear()
      let min = max - 50
      let years = []

      for (let i = max; i >= min; i--) {
        years.push({ name: i })
      }
      return years
    },
  },
  created() {
    const support = {
      header: '',
      content: `Nous sommes à votre disposition pour vous accompagner dans l’élaboration de votre parcours et votre projet. Contactez-nous au 01 46 40 00 04.`,
    }
    this.$store.commit('app/SET_SUPPORT', support)
    this.$store.commit('app/SHOW_SUPPORT')
    window.scrollTo(0, 0)
  },
  methods: {
    AddExperience() {
      this.experiences.push({
        establishment: '',
        year: '',
        job: '',
      })
    },
    next() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return
        }
        this.isNextButtonDisabled = true
        let data = {}
        let userExperiences = []
        this.$lodash.forEach(this.experiences, (experience) => {
          userExperiences.push({
            customer_id: this.user.id,
            job_id: experience.job,
            establishment_id: experience.establishment,
            year: experience.year,
          })
        })
        data.experiences = userExperiences
        data.isDoula = this.isDoulaJob
        data.step = 3
        let params = {
          data: data,
          id: this.user.id,
        }
        this.$store
          .dispatch('app/saveUserData', params)
          .then((response) => {
            this.$store.commit('app/SET_USER', response.data)
            this.steps[2].status = 'completed'
            this.steps[3].status = 'completed'
            this.currentStep = this.steps[3]
          })
          .catch(() => {
            this.isNextButtonDisabled = false
            this.Toast.fire({
              icon: 'warning',
              title: 'Une erreur est survenue',
            })
          })
      })
    },
    deleteExperience(index) {
      this.experiences.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>.remove-circle {
  color: #db7657;
}

.trash {
  color: #ced4da;
  z-index: 3;
}</style>
