<template>
  <div
    class="modal fade"
    id="cart-modal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="cartModalLabel"
    aria-hidden="true"
  >

    <div class="modal-dialog cartModal modal-dialog-centered modal-sm">
      <div class="modal-content border-0">
        <div class="modal-header border-0">
          <h5
            class="modal-title"
            id="cartModalLabel"
          ></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body p-5 pt-4">
          <div class="col-lg-12 col-sm-12 mb-2">
            <div class="text-center text-primary fw-bold mb-3">Session ajoutée au panier. Merci</div>
            <div class="col-lg-12 col-sm-12 mb-3">
              <atom-button
                @click="goToCart"
                btn-class="btn btn-primary m-auto w-100"
                type="button"
                title="Aller au panier"
              />
            </div>
            <div class="col-lg-12 col-sm-12 mb-2">
              <atom-button
                @click="returnToArchive"
                btn-class="btn btn-primary m-auto w-100"
                type="button"
                title="Continuer ses achats"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomButton from '@/components/atoms/AtomButton'
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'CartModal',
  components: { AtomButton },
  data() {
    return {
      session: '',
      sessionsOptions: [],
    }
  },
  computed: {
    ...mapFields('cart', ['cartModal']),
    ...mapFields('app', ['user']),

  },
  mounted() {
    this.$nextTick(() => {
      console.log('cart modal is mounted')
    })
  },
  methods: {
    goToCart() {
      window.location = '/panier'
    },
    returnToArchive() {
      window.location = '/formations'
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.modal-content {
  background-color: #f4f8fa !important;
  border-radius: 15px !important;
}
</style>
