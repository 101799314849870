<template>
  <div>
    <label class="col-form-label" :class="labelClass" for="">{{ label }}</label>
    <select
      :class="selectClass"
      :value="value"
      ref="my-select"
      @input="handleInput($event.target.value)"
    >
      <option value="" selected>{{ placeholder }}</option>
      <option
        v-for="(option, index) in options"
        :value="option.id || option.slug"
        :key="index"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'AtomSelect',
  props: {
    value: {
      // type: String,
      required: true,
    },
    label: {
      required: false,
      default: '',
    },
    selectClass: {
      required: true,
    },
    labelClass: {
      required: false,
      default: '',
    },
    placeholder: {
      required: false,
      default: '',
    },
    default: {
      required: false,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  mounted() {
    const elt = this.$refs['my-select']
    elt.addEventListener('change', () => {
      if (elt.options[0].selected === true) {
        // elt.classList.add('placeholder')
      } else {
        //elt.classList.remove('placeholder')
      }
    })
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style scoped>
.placeholder {
  color: grey !important;
}
select option:first-child {
  color: grey !important;
  /*display: none !important;*/
}
select option {
  /*color: #555 !important;*/
}
</style>
