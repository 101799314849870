var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-4" },
    [
      _c("div", { staticClass: "row mb-5" }, [
        _c("div", { staticClass: "col-md-12 mb-5" }, [
          _c("h3", { staticClass: "h3 mb-4" }, [
            _vm._v("Veuillez vérifier votre email"),
          ]),
          _c("h4", { staticClass: "h4 text-primary mb-5 fw-bold" }, [
            _vm._v(
              "Nous avons enregistré vos informations de compte. Maintenant, nous allons vérifier votre adresse e-mail."
            ),
            _c("br"),
            _vm._v(
              " Pour valider votre compte, cliquez sur le lien dans l'e-mail que nous avons envoyé à " +
                _vm._s(_vm.user.email)
            ),
          ]),
          _c("p", [
            _vm._v("Vous n'avez pas reçu? "),
            _c(
              "a",
              {
                staticClass: "text-decoration-underline",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.resendEmailtoConfirm.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" Ré-envoyer l'email")]
            ),
          ]),
        ]),
      ]),
      _c("support-box"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }