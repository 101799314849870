<template>
  <button
    class="btn"
    :class="`d-flex justify-content-center ${btnClass}`"
    :type="type"
    :disabled="disabled"
    @click.prevent.stop="handleClick"
  >
    <div
      v-if="spinner"
      class="spinner-border spinner-border-sm text-light me-2"
      role="status"
    >
      <span class="sr-only"></span>
    </div>
    <svg-icon v-if="icon" :icon-class="icon" :className="iconClassName" />
    <div>
      {{ title }}
    </div>
  </button>
</template>

<script>
export default {
  name: 'AtomButton',
  props: {
    btnClass: {
      type: String,
      required: false,
      default: 'btn-primary',
    },
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    title: {
      type: String,
      required: false,
      default: 'OK',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    iconClassName: {
      type: String,
      required: false,
      default: 'plus-circle',
    },
    spinner: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt)
    },
  },
}
</script>
