// import lodash from 'lodash-es'
import requestWP from '@/utils/request-wp'
import request from '@/utils/request'
import axios from 'axios'
import Qs from 'qs'
export function generateJwtKey(data) {
  return requestWP({
    url: window.ajaxurl,
    method: 'post',
    data: Qs.stringify(data),
    transformRrequest: (data) => {
      return data
    },
  })
}
export function postCart(data) {
  return requestWP({
    url: window.ajaxurl,
    method: 'post',
    data: Qs.stringify(data),
    transformRrequest: (data) => {
      return data
    },
  })
}
export function save(data) {
  console.log('fetch cart = ', data)
  return requestWP({
    url: window.ajaxurl,
    method: 'post',
    data: Qs.stringify(data),
    transformRrequest: (data) => {
      return data
    },
  })
}
export function clear(data) {
  console.log('clear cart = ', data)
  return requestWP({
    url: window.ajaxurl,
    method: 'post',
    data: Qs.stringify(data),
    transformRrequest: (data) => {
      return data
    },
  })
}
export function setOrder(data) {
  return request({
    url: '/orders',
    method: 'post',
    data: data,
  })
}

export function setCartEvents(data) {
  return request({
    url: '/cart-events',
    method: 'post',
    data: data,
  })
}

export function setCustomerCartBrevo(data) {
  return request({
    url: '/customers/' + data.customerId + '/cart-events/' + data.event,
    method: 'put',
    data: {},
  })
}

export function setCheckoutOrder(data) {
  return request({
    url: '/orders/pay',
    method: 'post',
    data: data,
  })
}
export function setPromoCode(data) {
  return request({
    url: `/codespromo/${data.code}/available-courses`,
    method: 'post',
    data: { details: data?.details ?? {} },
  })
}
export function createAlmaPaymentApi({ paymentData, almaApiKey }) {
  console.log({ paymentData, almaApiKey })
  return axios.post(
    `${process.env.VUE_APP_ALMA_BASE_URL}/v1/payments`,
    paymentData,
    {
      headers: {
        Authorization: 'Alma-Auth ' + almaApiKey,
      },
    }
  )

  request({
    url: '/orders/pay',
    method: 'post',
    data: data,
  })
}

export function checkEligibility(amount) {
  /*return new Promise((resolve) => {
    setTimeout(() => {
      if (amount <= 0) resolve([])
      const data = [
        {
          eligible: true,
          installments_count: 3,
        },
        {
          eligible: true,
          installments_count: 4,
        },
      ]

      resolve(data)
    }, 1000)
  })*/
  return request({
    url: '/payments/eligibility',
    method: 'post',
    data: { amount },
  })
}
