import requestWP from '@/utils/request-wp'
import request from '@/utils/request'
import * as Qs from 'qs'

export function login(data) {
  return requestWP({
    url: window.ajaxurl,
    method: 'post',
    data: Qs.stringify(data),
    transformRrequest: (data) => {
      return data
    },
  })
}

export function logout() {
  return requestWP({
    url: window.ajaxurl,
    method: 'post',
    data: Qs.stringify({ action: 'logout' }),
    transformRrequest: (data) => {
      return data
    },
  })
}

export function getAccount(data) {
  return requestWP({
    url: window.ajaxurl,
    method: 'post',
    data: Qs.stringify(data),
    transformRrequest: (data) => {
      return data
    },
  })
}

export function editUserAccount(data) {
  return request({
    url: '/customers/contact/' + data.id,
    method: 'put',
    data: data.data,
  })
}

export function updateProfile(data) {
  return request({
    url: '/customers/' + data.id,
    method: 'put',
    data: data.data,
    params: data.action,
  })
}

export function updateExperiences(data) {
  return request({
    url: '/customers/' + data.id,
    method: 'put',
    data: data.data,
    params: data.action,
  })
}

export function loginOnbehalfUser(data) {
  return requestWP({
    url: window.ajaxurl,
    method: 'post',
    data: Qs.stringify(data),
    transformRrequest: (data) => {
      return data
    },
  })
}

export function sendQuoteRequestWP(data) {
  return requestWP({
    url: window.ajaxurl,
    method: 'post',
    data: Qs.stringify(data),
    transformRrequest: (data) => {
      return data
    },
  })
}

export function sendQuoteRequestTO(data) {
  let date = new Date()

  let d = {
    TOorder: {
      OrderDate: {
        Years: date.getFullYear(),
        Months: date.getMonth() + 1,
        Days: date.getDate(),
        Hours: date.getHours(),
        Minutes: date.getMinutes(),
        Seconds: date.getSeconds(),
      },
      ClientID: data.user.clientId,
      BeneficiaryClientID: data.user.clientId,
      TrainingProviderExternalCode: 'ETB01',
      AccountManagerID: data.typeId,
      TrainingServices: data.training.map((i) => {
        return {
          ServiceType: {
            Code: 'FORMINTER',
            Name: 'Public course',
          },
          CourseID: i.toId,
          ServiceOffer: {
            Active: true,
            CourseID: i.toId,
            PrincipalDetail: {
              TarificationType: {
                Code: 'FP',
                Name: 'Forfait/Pers.',
              },
              AmountHT: {
                Value: i?.offerPrice ?? 0,
                CodeISOCurrency: 'eur',
              },
              VAT: {
                Code: 'TVA0',
                Name: '0%',
              },
            },

            AppendiceDetails: [],
          },
          Participants: [
            {
              TraineeID: data.user.personId,
            },
          ],
        }
      }),
    },
    customer: {
      customer_firstname: data.firstname,
      customer_lastname: data.lastname,
      customer_email: data.email,
      customer_country: data.country,
      customer_phone: data.phone,
      customer_company: data.company,
      customer_address: data.address,
      customer_zipcode: data.zipcode,
      customer_city: data.city,
      message: data.message,
    },
  }

  return request({
    url: '/demande-devis',
    method: 'post',
    data: d,
  })
}

export function sendCheckMyEligibility(data) {
  return requestWP({
    url: window.ajaxurl,
    method: 'post',
    data: data,
    transformRrequest: (data) => {
      return data
    },
  })
}

export function getTrainings(data) {
  return request({
    url: '/customers/' + data.user.id + '/available-courses?include=sessions',
    method: 'get',
    data: data.data,
    params: data.action,
  })
}

export function getDevisTypes() {
  return request({
    url: '/quotes',
    method: 'get',
  })
}

export function sendAccountUpdateRequest(data) {
  return requestWP({
    url: window.ajaxurl,
    method: 'post',
    data: Qs.stringify(data),
    transformRrequest: (data) => {
      return data
    },
  })
}

export function resetPassword(data) {
  return request({
    url: '/customers/forgot-password',
    method: 'post',
    data: data,
  })
}

export function changePassword(data) {
  return request({
    url: '/customers/reset-password',
    method: 'post',
    data: data,
  })
}

export function editPassword(data) {
  return request({
    url: '/change-password/' + data.id,
    method: 'put',
    data: data.data,
  })
}

export function fetchCategories() {
  return request({
    url: '/categories',
    method: 'get',
  })
}

export function fetchParcours() {
  return request({
    url: '/parcours',
    method: 'get',
  })
}

export function fetchRegions() {
  return request({
    url: '/regions',
    method: 'get',
  })
}

export function fetchCities() {
  return request({
    url: '/cities',
    method: 'get',
  })
}

export function deleteUserDiploma(data) {
  return request({
    url: '/customers/diplomas/' + data,
    method: 'delete',
  })
}
