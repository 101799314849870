var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "label",
      {
        staticClass: "col-form-label",
        class: _vm.labelClass,
        attrs: { for: "" },
      },
      [_vm._v(_vm._s(_vm.label))]
    ),
    _c(
      "input",
      _vm._b(
        {
          class: _vm.inputClass,
          attrs: {
            type: _vm.type,
            placeholder: _vm.placeholder,
            pattern: _vm.pattern,
            disabled: _vm.disabled,
          },
          domProps: { value: _vm.value },
          on: {
            input: function ($event) {
              return _vm.handleInput($event.target.value)
            },
          },
        },
        "input",
        _vm.$attrs,
        false
      )
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }