<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'CartApp',
  props: ['cart'],
  mounted() {
    this.$nextTick(() => {
      if (this.cart) {
        this.$store.commit('cart/SET_CART_ITEMS', this.cart)
      }
    })
  },
}
</script>
