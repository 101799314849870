import Cookies from 'js-cookie'
import lodash from 'lodash-es'
import EventBus from '@/utils/event-bus'
import { getField, updateField } from 'vuex-map-fields'
import {
  fetchMetiers,
  fetchEstablishments,
  fetchDiplomas,
  updateUserData as saveUserInfo,
  setUserInformations as setUserInfo,
  confirmUserAccount as confirmAccountUser,
} from '@/api/inscription'
import {
  login as loginUser,
  logout as logout,
  resetPassword as resetPassword,
  getAccount as getAccount,
  fetchCategories,
  fetchParcours,
  fetchRegions,
  fetchCities,
  loginOnbehalfUser,
  editUserAccount,
  updateProfile,
  deleteUserDiploma,
  changePassword,
  editPassword,
  sendQuoteRequestWP,
  sendQuoteRequestTO,
  sendCheckMyEligibility,
  sendAccountUpdateRequest,
  updateExperiences,
  getTrainings,
  getDevisTypes,
} from '@/api/app.js'

import {
  SET_API_BASEURL,
  SET_USER,
  UPDATE_USER,
  SET_SUPPORT,
  SHOW_SUPPORT,
  HIDE_SUPPORT,
  SET_METIERS,
  SET_ESTABLISHMENTS,
  SET_DIPLOMAS,
  SET_LOGIN,
  SET_CATEGORIES,
  SET_REGIONS,
  SET_CITIES,
  SET_PARCOURS,
  SET_TRAININGS,
  SET_DEVIS_TYPES,
  SET_VIDEO_MODAL,
} from '@/store/mutation-types'

const state = {
  apiBaseURL: '',
  isAuthenticated: false,
  isCardLoginIsShowing: false,
  isConnecting: false,
  redirectTo: null,
  loginMessage: null,
  user: null,
  support: {
    show: false,
    header: '',
    content: '',
  },
  metiers: [],
  establishments: [],
  diplomas: [],
  regionOptions: [],
  cityOptions: [],
  thematiqueOptions: [],
  parcours: [],
  modaliteOptions: [
    {
      id: '1',
      name: 'Pratique',
    },
    {
      id: '2',
      name: 'Théorique',
    },
  ],
  formatOptions: [
    {
      id: '1',
      name: 'Visio',
    },
    {
      id: '2',
      name: 'Présentiel',
    },
    {
      id: '4',
      name: 'E-learning',
    },
  ],
  cssVars: {
    primary: '#5CA3B3',
  },
  quoteRequestModal: '',
  checkMyEligibilityModal: '',
  trainings: [],
  devisTypes: [],
  videoModal: null,
}

const mutations = {
  updateField,
  [SET_API_BASEURL](state, data) {
    state.apiBaseURL = data
  },
  [SET_VIDEO_MODAL](state, modal) {
    state.videoModal = modal
  },
  [SET_USER](state, data) {
    state.user = data
  },
  [UPDATE_USER](state, data) {
    state.user = { ...state.user, ...data }
  },
  [SET_SUPPORT](state, data) {
    state.support = { ...state.support, ...data }
  },
  [SHOW_SUPPORT](state) {
    Cookies.set('hide-support', false)
    state.support = { ...state.support, ...{ show: true } }
  },
  [HIDE_SUPPORT](state) {
    Cookies.set('hide-support', true)
    state.support = { ...state.support, ...{ show: false } }
  },
  [SET_METIERS](state, data) {
    state.metiers = data
  },
  [SET_ESTABLISHMENTS](state, data) {
    state.establishments = data
  },
  [SET_DIPLOMAS](state, data) {
    state.diplomas = data
  },
  [SET_LOGIN](state, status) {
    EventBus.$emit('authentication.changed')
    state.isAuthenticated = status
  },
  [SET_CATEGORIES](state, options) {
    state.thematiqueOptions = options
  },
  [SET_REGIONS](state, options) {
    state.regionOptions = options
  },
  [SET_CITIES](state, options) {
    state.cityOptions = options
  },
  [SET_PARCOURS](state, options) {
    state.parcours = options
  },
  [SET_TRAININGS](state, options) {
    state.trainings = options
  },
  [SET_DEVIS_TYPES](state, options) {
    console.log('SET_DEVIS_TYPES ', options)
    state.devisTypes = options
  },
}

const actions = {
  async getTrainings(context = {}) {
    if (context.state.user) {
      const res = await getTrainings(context.state)
      context.commit('SET_TRAININGS', res.data)
    }
    //return res
  },
  async getDevisTypes(context = {}) {
    const res = await getDevisTypes()
    context.commit('SET_DEVIS_TYPES', res.data)
    return res
  },
  async fetchMetiers(context = {}) {
    const res = await fetchMetiers()
    context.commit('SET_METIERS', res.data)
    return res
  },
  async fetchEstablishments(context = {}) {
    const res = await fetchEstablishments()
    context.commit('SET_ESTABLISHMENTS', res.data)
    return res
  },
  async fetchDiplomas(context = {}) {
    const res = await fetchDiplomas()
    context.commit('SET_DIPLOMAS', res.data)
    return res
  },
  async login({ commit }, params = {}) {
    const res = await loginUser(params)
    if (res) {
      commit('SET_USER', res)
    }
    return res
  },
  async logout({ commit }, params = {}) {
    const res = await logout(params)
    commit('SET_LOGIN', false)
    return res
  },
  // eslint-disable-next-line no-unused-vars
  async forgotpassword(_, params = {}) {
    const res = await resetPassword(params)
    return res
  },
  // eslint-disable-next-line no-unused-vars
  async resetPassword(_, params = {}) {
    const res = await changePassword(params)
    return res
  },
  // eslint-disable-next-line no-unused-vars
  async changingPassword(_, params = {}) {
    const res = await editPassword(params)
    return res
  },
  // eslint-disable-next-line no-unused-vars
  async getUserAccount({ commit }) {
    let data = {
      action: 'get_account',
    }
    const res = await getAccount(data)
    if (lodash.isEmpty(res)) {
      commit('SET_LOGIN', false)
    } else {
      commit('SET_LOGIN', true)
      commit('SET_USER', res)
    }
    return res
  },
  async editAccoutnInformation({ commit }, params = {}) {
    const res = await editUserAccount(params)
    if (res) {
      commit('UPDATE_USER', res.data)
    }
    return res
  },
  // eslint-disable-next-line no-unused-vars
  async deleteDiploma(_, params = {}) {
    const res = await deleteUserDiploma(params)
    return res
  },
  async updateProfileInformations({ commit }, params = {}) {
    const res = await updateProfile(params)
    if (res) {
      commit('UPDATE_USER', res.data)
    }
    return res
  },
  async editUserExperiences({ commit }, params = {}) {
    const res = await updateExperiences(params)
    if (res) {
      commit('UPDATE_USER', res.data)
    }
    return res
  },
  // eslint-disable-next-line no-unused-vars
  async sendQuoteRequest(_, params = {}) {
    await sendQuoteRequestWP(params)
    await sendQuoteRequestTO(params)
    //return res
  },
  async sendCheckMyEligibility(_, params = {}) {
    const res = await sendCheckMyEligibility(params)
    return res
  },
  // eslint-disable-next-line no-unused-vars
  async sendAccountUpdateRequest(_, params = {}) {
    const res = await sendAccountUpdateRequest(params)
    return res
  },
  saveUserData({ commit }, params = {}) {
    return saveUserInfo(params).then((response) => {
      if (response) {
        commit('UPDATE_USER', response.data)
        return response
      }
    })
  },
  setUserData({ commit }, params = {}) {
    return setUserInfo(params).then((response) => {
      if (response) {
        commit('SET_USER', response.data)
        return response
      }
    })
  },
  confirmUserAccount({ commit }, params = {}) {
    return confirmAccountUser(params).then((response) => {
      if (response) {
        commit('SET_USER', response)
        return response
      }
    })
  },
  getCategories({ commit }) {
    return fetchCategories().then((response) => {
      if (response) {
        let categories = response.data.filter(
          (item) => item.parentName == 'Racine'
        )
        commit('SET_CATEGORIES', categories)
        return response
      }
    })
  },
  getParcours({ commit }) {
    return fetchParcours().then((response) => {
      if (response.data) {
        commit('SET_PARCOURS', response.data)
        return response
      }
    })
  },
  getRegions({ commit }) {
    return fetchRegions().then((response) => {
      if (response.data) {
        commit('SET_REGIONS', response.data)
        return response
      }
    })
  },
  getCities({ commit }) {
    return fetchCities().then((response) => {
      if (response.data) {
        commit('SET_CITIES', response.data)
        return response
      }
    })
  },
  loginOnbehalfUser({ state, commit }) {
    let params = {
      action: 'login_on_behalf_user',
      id: state.user.id,
    }
    return loginOnbehalfUser(params).then((response) => {
      if (response && (response?.emailVerified ?? false)) {
        commit('SET_USER', response)
        return response
      }
    })
  },
}

const getters = { getField }

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
