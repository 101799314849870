<template>
  <div class="">
    <div>
      <div class="step__number" v-if="step.status == ''">
        {{ step.number }}
      </div>
      <div
        class=""
        v-if="step.status == 'inValidation' || step.status == 'validated'"
      >
        <svg-icon icon-class="cog" className="icon" />
      </div>
      <div class="" v-if="step.status == 'completed'">
        <svg-icon icon-class="check" className="icon" />
      </div>
      <div class="step__title" v-if="lgBreakpoint">{{ step.title }}</div>
    </div>
  </div>
</template>

<script>
import { useBreakpoints, breakpointsBootstrapV5 } from '@vueuse/core'

import { mapFields } from 'vuex-map-fields'
export default {
  name: 'Step',
  props: ['step'],
  setup() {
    const breakpoints = useBreakpoints(breakpointsBootstrapV5)
    const lgBreakpoint = breakpoints.greater('sm')
    return {
      lgBreakpoint,
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields('inscription', ['steps', 'currentStep']),
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.icon {
  color: #f4f8fa;
}
</style>
