var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-check" }, [
    _c("input", {
      staticClass: "form-check-input",
      attrs: { type: "checkbox", id: _vm.label },
      domProps: { value: _vm.value },
      on: {
        change: function ($event) {
          return _vm.handleCheck($event.target.checked)
        },
      },
    }),
    _vm.link
      ? _c("label", { class: _vm.labelClass, attrs: { for: _vm.label } }, [
          _c("a", { attrs: { href: _vm.link, target: "_blank" } }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
        ])
      : _c("label", { class: _vm.labelClass, attrs: { for: _vm.label } }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }